import { apiUrl } from "./config";

const getProductions = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/productions", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const saveProduction = async (production) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/productions", {
      method: "POST",
      headers: {
        'Authorization': 'Bearer ' + token,  // No Content-Type header here
      },
      body: production,  // Pass the FormData directly
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};


const updateProduction = async (productionData, id) => {
  const token = localStorage.getItem('accessToken');
  const formData = new FormData();

  // Append all fields to formData, except items
  Object.keys(productionData).forEach(key => {
    if (key === 'items') {
      // Skip items here; we'll append them separately
      return;
    }

    if (key === 'fileUpload' && productionData[key] instanceof File) {
      formData.append(key, productionData[key]); // Add file if it exists
    } else if (productionData[key] !== undefined && productionData[key] !== null) {
      formData.append(key, productionData[key].toString()); // Add other fields
    }
  });

  // Append items as an array to FormData if they exist
  if (Array.isArray(productionData.items)) {
    productionData.items.forEach((item, index) => {
      formData.append(`items[${index}][name]`, item.name);
      formData.append(`items[${index}][type]`, item.type);
      formData.append(`items[${index}][quantity]`, item.quantity);
    });
  }

  // Log formData for debugging purposes (optional)
  for (let pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }

  try {
    // Change method to POST
    const response = await fetch(`${apiUrl}/productions/${id}`, {
      method: 'POST', // Using POST instead of PUT
      headers: {
        "accept": "*/*",
        'Authorization': 'Bearer ' + token
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating production:', error);
    throw error;
  }
};





const deleteProduction = async (productionId) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/productions/" + productionId, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

const getActivePlanningProductions = async () => {
  try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/productions-active-planning`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          },
      });

      const responseData = await response.json();

      if (response.ok) {
          return responseData.active_plannings;
      } else {
          throw new Error(responseData.errors);
      }
  } catch (error) {
      throw new Error(error.message);
  }
};
export {
  getProductions,
  saveProduction,
  deleteProduction,
  getActivePlanningProductions ,
  updateProduction
};
