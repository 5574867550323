import { apiUrl } from "./config";

const getProjectMembers = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/project/members", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getMyProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/my-projects", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getProjects = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/projects", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getProjectInfo = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/project-info", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

const switchProject = async (project_id) => {
  try {
    const token = localStorage.getItem("accessToken");

    const data = {
      project_id: project_id,
    };

    const response = await fetch(apiUrl + "/switch-project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

const saveProject = async (project) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(apiUrl + "/projects", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(project),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

const updateProjectInfo = async (id, name, description, status) => {
  try {
    const token = localStorage.getItem("accessToken");

    const data = {
      id: id,
      name: name,
      description: description,
      status: status,
    };

    const response = await fetch(apiUrl + "/project", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    return error;
  }
};

const updateProjectImage = async (file, current_project_id) => {
  try {
    const token = localStorage.getItem("accessToken");

    if (file.size > 2 * 1024 * 1024) {
      window.alert("File size exceeds 2MB limit.");
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("project_id", current_project_id);

    const response = await fetch(apiUrl + "/projects/image", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
      body: formData,
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};
const updateArchiveStatus = async (id, data) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(`${apiUrl}/projects/${id}/archive`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};
const getProjectDetails = async (id) => {
  try {
    const token = localStorage.getItem("accessToken");

    const response = await fetch(`${apiUrl}/project-details/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    const responseData = await response.json();

    if (response.ok) {
      return responseData;
    } else {
      throw new Error(responseData.errors);
    }
  } catch (error) {
    throw new Error(error);
  }
};

// here
const getProjectsStartedPerMonth = async () => {
  try {
      const token = localStorage.getItem("accessToken");

      const response = await fetch(`${apiUrl}/projects-started`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          },
      });

      const responseData = await response.json();

      if (response.ok) {
          return responseData;
      } else {
          throw new Error(responseData.errors);
      }
  } catch (error) {
      throw new Error(error.message);
  }
};

export const getDetailedProjects = async () => {
  try {
      const token = localStorage.getItem('accessToken');

      const response = await fetch(`${apiUrl}/projects`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });

      const responseData = await response.json();

      if (response.ok) {
          return responseData;
      } else {
          throw new Error(responseData.errors);
      }
  } catch (error) {
      console.error('Error fetching projects:', error);
      throw error;
  }
};
export const getProjectCollectionsSummary = async () => {
  try {
      const token = localStorage.getItem('accessToken');

      const response = await fetch(`${apiUrl}/project-collections-summary`, {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });

      const responseData = await response.json();

      if (response.ok) {
          return responseData;
      } else {
          throw new Error(responseData.errors);
      }
  } catch (error) {
      console.error('Error fetching project collections summary:', error);
      throw error;
  }
};
export {
  getProjectMembers,
  getMyProjects,
  saveProject,
  getProjectInfo,
  switchProject,
  updateProjectInfo,
  getProjects,
  updateProjectImage,
  getProjectDetails,
  updateArchiveStatus, // Add this line
  getProjectsStartedPerMonth,
  
};
