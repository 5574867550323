import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getProductions, updateProduction } from '../../api/production';
import UpdateProductionModal from '../../components/projectManagement/UpdateProductionModal';
import UpdatePerformedModal from '../../components/planner/UpdatePerformedModal';
import CreateProductionModal from '../../components/projectManagement/CreateProductionModal'; // Import the CreateProductionModal

function PlaningTable() {
  const { t } = useTranslation();
  const [productions, setProductions] = useState([]);
  const [planningProductions, setPlanningProductions] = useState([]);
  const [measuringProductions, setMeasuringProductions] = useState([]);
  const [newProductions, setNewProductions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showUpdateProductionModal, setShowUpdateProductionModal] = useState(false);
  const [showUpdatePerformedModal, setShowUpdatePerformedModal] = useState(false);
  const [showCreateProductionModal, setShowCreateProductionModal] = useState(false); // State for CreateProductionModal
  const [currentProduction, setCurrentProduction] = useState(null);

  const fetchProductions = async () => {
    try {
      const fetchedProductions = await getProductions();
      setProductions(fetchedProductions);
      filterProductions(fetchedProductions, searchTerm);
    } catch (error) {
      console.error('Error fetching productions:', error);
    }
  };

  useEffect(() => {
    fetchProductions();
  }, []);

  useEffect(() => {
    filterProductions(productions, searchTerm);
  }, [searchTerm, productions]);

  const filterProductions = (productionsList, term) => {
    const lowerCaseTerm = term.toLowerCase();
    const filteredPlanning = productionsList.filter(production =>
      production.status === 'planning' && (
        (production.project_id && production.project_id.toString().includes(lowerCaseTerm)) ||
        (production.company && production.company.toLowerCase().includes(lowerCaseTerm)) ||
        (production.site_city && production.site_city.toLowerCase().includes(lowerCaseTerm)) ||
        (production.notes && production.notes.toLowerCase().includes(lowerCaseTerm))
      )
    );

    const filteredMeasuring = productionsList.filter(production =>
      production.status === 'measuring' && (
        (production.project_id && production.project_id.toString().includes(lowerCaseTerm)) ||
        (production.company && production.company.toLowerCase().includes(lowerCaseTerm)) ||
        (production.site_city && production.site_city.toLowerCase().includes(lowerCaseTerm)) ||
        (production.notes && production.notes.toLowerCase().includes(lowerCaseTerm))
      )
    );

    const filteredNew = productionsList.filter(production =>
      production.status === 'new' && (
        (production.company && production.company.toLowerCase().includes(lowerCaseTerm)) ||
        (production.site_city && production.site_city.toLowerCase().includes(lowerCaseTerm)) ||
        (production.notes && production.notes.toLowerCase().includes(lowerCaseTerm))
      )
    );

    setPlanningProductions(filteredPlanning);
    setMeasuringProductions(filteredMeasuring);
    setNewProductions(filteredNew);
  };

  const handleShowUpdateProductionModal = (production) => {
    setCurrentProduction(production);
    setShowUpdateProductionModal(true);
  };

  const handleShowUpdatePerformedModal = (production) => {
    setCurrentProduction(production);
    setShowUpdatePerformedModal(true);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const addProduction = (newProduction) => {
    setProductions([...productions, newProduction]);
    setNewProductions([...newProductions, newProduction]); // Add new production to 'new' category
  };

  return (
    <>
      {/* Button to open the Create Production Modal */}
      <button 
        className="btn btn-primary mb-3" 
        onClick={() => setShowCreateProductionModal(true)}
      >
        יצירת תכנון ומדידה חדשה
      </button>

      {/* Search Input */}
      <input 
        type="text" 
        placeholder="חיפוש" 
        value={searchTerm} 
        onChange={handleSearch} 
        className="form-control my-3" 
      />

      <div className="row">
        {/* New Status List */}
        <div className="col-md-4">
          <div className="bg-white rounded p-3 shadow-sm" style={{ overflowX: 'auto' }}>
            <h6 className="bold mb-3">חדש</h6>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>אתר עיר</th>
                  <th>חברה</th>
                  <th>הערות</th>
                  <th>סטטוס</th>
                  <th>פעולות</th>
                </tr>
              </thead>
              <tbody>
                {newProductions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((production) => (
                  <tr key={production.id}>
                    <td>{production.site_city}</td>
                    <td>{production.company}</td>
                    <td>{production.notes}</td>
                    <td>חדש</td>
                    <td>
                      <button className="btn btn-secondary" onClick={() => handleShowUpdatePerformedModal(production)}>עריכה</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: Math.ceil(newProductions.length / itemsPerPage) }, (_, index) => (
                <button
                  key={index}
                  className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Measuring List */}
        <div className="col-md-4">
          <div className="bg-white rounded p-3 shadow-sm" style={{ overflowX: 'auto' }}>
            <h6 className="bold mb-3">מדידה</h6>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>מספר פרוייקט</th>
                  <th>חברה</th>
                  <th>אתר עיר</th>
                  <th>סטטוס</th>
                  <th>ביצוע ע"י</th>
                  <th>הערות</th>
                  <th>דחיפות</th>
                  <th>פעולות</th>
                </tr>
              </thead>
              <tbody>
                {measuringProductions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((production) => (
                  <tr key={production.id}>
                    <td>{production.project_id}</td>
                    <td>{production.company}</td>
                    <td>{production.site_city}</td>
                    <td>מדידה</td>
                    <td>{production.performed_by}</td>
                    <td>{production.notes}</td>
                    <td>
                      <span className={`badge ${production.urgency === 'high' ? 'text-bg-danger' : production.urgency === 'medium' ? 'text-bg-warning' : 'text-bg-info'}`}>
                        {production.urgency === 'high' ? 'דחוף' : production.urgency === 'medium' ? 'בינוני' : 'נמוך'}
                      </span>
                    </td>
                    <td>
                      <button className="btn btn-secondary" onClick={() => handleShowUpdateProductionModal(production)}>עריכה</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: Math.ceil(measuringProductions.length / itemsPerPage) }, (_, index) => (
                <button
                  key={index}
                  className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Planning List */}
        <div className="col-md-4">
          <div className="bg-white rounded p-3 shadow-sm" style={{ overflowX: 'auto' }}>
            <h6 className="bold mb-3">תכנון</h6>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>מספר פרוייקט</th>
                  <th>חברה</th>
                  <th>אתר עיר</th>
                  <th>סטטוס</th>
                  <th>ביצוע ע"י</th>
                  <th>הערות</th>
                  <th>דחיפות</th>
                  <th>פעולות</th>
                </tr>
              </thead>
              <tbody>
                {planningProductions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((production) => (
                  <tr key={production.id}>
                    <td>{production.project_id}</td>
                    <td>{production.company}</td>
                    <td>{production.site_city}</td>
                    <td>תכנון</td>
                    <td>{production.performed_by}</td>
                    <td>{production.notes}</td>
                    <td>
                      <span className={`badge ${production.urgency === 'high' ? 'text-bg-danger' : production.urgency === 'medium' ? 'text-bg-warning' : 'text-bg-info'}`}>
                        {production.urgency === 'high' ? 'דחוף' : production.urgency === 'medium' ? 'בינוני' : 'נמוך'}
                      </span>
                    </td>
                    <td>
                      <button className="btn btn-secondary" onClick={() => handleShowUpdateProductionModal(production)}>עריכה</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: Math.ceil(planningProductions.length / itemsPerPage) }, (_, index) => (
                <button
                  key={index}
                  className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-light'}`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Items Per Page Selector */}
      <div className="d-flex justify-content-start mb-3">
        <label htmlFor="itemsPerPage" className="me-2">כמות עמודות</label>
        <select 
          id="itemsPerPage" 
          value={itemsPerPage} 
          onChange={handleItemsPerPageChange} 
          className="form-select w-auto"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>

      {/* Create Production Modal */}
      {showCreateProductionModal && (
        <CreateProductionModal 
          show={showCreateProductionModal}
          handleClose={() => setShowCreateProductionModal(false)}
          addProduction={addProduction}
        />
      )}

      {/* Existing modals */}
      {showUpdateProductionModal && (
        <UpdateProductionModal 
          production={currentProduction}
          showUpdateProductionModal={showUpdateProductionModal} 
          setShowUpdateProductionModal={setShowUpdateProductionModal} 
          setProductions={setProductions}
        />
      )}

      {showUpdatePerformedModal && (
        <UpdatePerformedModal 
          production={currentProduction}
          show={showUpdatePerformedModal}
          setShow={setShowUpdatePerformedModal}
          updateProduction={updateProduction}
        />
      )}
    </>
  );
}

export default PlaningTable;
