import React, { useState } from 'react';
import { Button, Form, Container } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Assuming you have react-toastify installed
import { generatePdf } from '../../api/pdf'; // Import the API function

function PdfFormPage() {
    const [formData, setFormData] = useState({
        clientName: '',
        projectName: '',
        company: '',
        city: '',
        phone: '',
        email: '',
        writtenBy: '',
        products: [
            { description: '', price: '', unitOfMeasure: '', quantity: '', remarks: '' },
        ],
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleProductChange = (index, e) => {
        const { name, value } = e.target;
        const updatedProducts = [...formData.products];
        updatedProducts[index][name] = value;
        setFormData({ ...formData, products: updatedProducts });
    };

    const addProduct = () => {
        setFormData({
            ...formData,
            products: [...formData.products, { description: '', price: '', unitOfMeasure: '', quantity: '', remarks: '' }],
        });
    };

    const removeProduct = (index) => {
        const updatedProducts = [...formData.products];
        updatedProducts.splice(index, 1);
        setFormData({ ...formData, products: updatedProducts });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await generatePdf(formData);
            // if (response.ok) {
                toast.success('הצעת מחיר נשלחה בהצלחה');
            // } else {
            //     toast.error('Failed to create PDF');
            // }
        } catch (error) {
            toast.error('Error generating PDF');
        }
    };

    return (
        <Container dir="rtl" className="bg-white rounded p-3 shadow-sm mt-4">
            <h1 className="mb-4">יצירת הצעת מחיר</h1>
            <Form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>שם לקוח</Form.Label>
                            <Form.Control
                                type="text"
                                name="clientName"
                                value={formData.clientName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>שם פרוייקט</Form.Label>
                            <Form.Control
                                type="text"
                                name="projectName"
                                value={formData.projectName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>חברה</Form.Label>
                            <Form.Control
                                type="text"
                                name="company"
                                value={formData.company}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>עיר</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>טלפון</Form.Label>
                            <Form.Control
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>אימייל</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group>
                            <Form.Label>נכתב ע"י</Form.Label>
                            <Form.Control
                                type="text"
                                name="writtenBy"
                                value={formData.writtenBy}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </div>
                </div>
                <h5 className="mt-4">פרטי מוצרים</h5>
                {formData.products.map((product, index) => (
                    <div key={index} className="border p-3 mb-3">
                        <h6>מוצר {index + 1}</h6>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <Form.Group>
                                    <Form.Label>תיאור מוצר</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={product.description}
                                        onChange={(e) => handleProductChange(index, e)}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-2 mb-3">
                                <Form.Group>
                                    <Form.Label>מחיר</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        value={product.price}
                                        onChange={(e) => handleProductChange(index, e)}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-2 mb-3">
                                <Form.Group>
                                    <Form.Label>יחידות מידה</Form.Label>
                                    <Form.Select
                                        name="unitOfMeasure"
                                        value={product.unitOfMeasure}
                                        onChange={(e) => handleProductChange(index, e)}
                                        required
                                    >
                                        <option value="">בחר יחידת מידה</option>
                                        <option value="מ.א">מ.א</option>
                                        <option value="מ.ר">מ.ר</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className="col-md-2 mb-3">
                                <Form.Group>
                                    <Form.Label>כמות</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="quantity"
                                        value={product.quantity}
                                        onChange={(e) => handleProductChange(index, e)}
                                        required
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-2 mb-3">
                                <Form.Group>
                                    <Form.Label>הערות</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="remarks"
                                        value={product.remarks}
                                        onChange={(e) => handleProductChange(index, e)}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        {formData.products.length > 1 && (
                            <Button variant="danger" onClick={() => removeProduct(index)} className="mb-3">
                                הסרת מוצר
                            </Button>
                        )}
                    </div>
                ))}
                <Button variant="secondary" onClick={addProduct} className="mb-3">
                    הוספת מוצר
                </Button>
                <Button variant="success" type="submit">
                    בניית הצעת מחיר
                </Button>
            </Form>
        </Container>
    );
}

export default PdfFormPage;
