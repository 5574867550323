import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { generateAccountDetailsPdf } from '../../api/pdf'; // Import the API function

export default function BillingDetailsForm() {
  const [billingData, setBillingData] = useState([
    { id: 1, month: '', description: '', location: '', unit: '', quantity: 0, unitPrice: 0, total: 0 },
    { id: 2, month: '', description: '', location: '', unit: '', quantity: 0, unitPrice: 0, total: 0 },
    // Add more rows if needed
  ]);

  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [documentProducer, setDocumentProducer] = useState('מאיר');
  const [emailSent, setEmailSent] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [vat, setVat] = useState(0);
  const [totalWithVat, setTotalWithVat] = useState(0);

  const VAT_PERCENTAGE = 0.17;

  const handleInputChange = (id, field, value) => {
    setBillingData(prevData =>
      prevData.map(item =>
        item.id === id
          ? {
              ...item,
              [field]: value,
              total: field === 'quantity' || field === 'unitPrice'
                ? value * (item[field === 'quantity' ? 'unitPrice' : 'quantity'])
                : item.total,
            }
          : item
      )
    );
  };

  const calculateTotals = () => {
    const subtotal = billingData.reduce((sum, item) => sum + item.total, 0);
    const vatAmount = subtotal * VAT_PERCENTAGE;
    const totalWithVat = subtotal + vatAmount;

    setSubtotal(subtotal);
    setVat(vatAmount);
    setTotalWithVat(totalWithVat);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      calculateTotals();

      // Create the data object to send to the server
      const data = {
        clientName,
        projectName,
        companyName,
        city,
        phone,
        documentProducer,
        emailSent,
        billingData,
        subtotal,
        vat,
        totalWithVat,
      };

      // Send the data to the backend
      await generateAccountDetailsPdf(data);
      toast.success('הנתונים הוגשו בהצלחה!');
    } catch (error) {
      toast.error('הגשה נכשלה');
    }
  };

  return (
    <div dir="rtl" className='container-fluid' style={{ backgroundColor: '#F3F3F5' }}>
      <div className="card p-0 w-100 bg-transparent" style={{ maxWidth: 'unset', minHeight: '100vh' }}>
        <div className="row m-0">
          <div className="col-md-12 px-0">
            <div style={{ minHeight: '100vh' }} className='d-flex justify-content-center align-items-center'>
              <div className='w-100 px-4 m-auto d-block' style={{ maxWidth: 800 }}>
                <div className='pt-3 pb-5'>
                  <span className='py-3 h3 bold'>פירוט חשבון  עריכה</span>
                </div>
                <form onSubmit={handleSubmit}>
                  {/* Customer Information side by side */}
                  <div className="row">
                    <div className="col-md-6">
                      <label>לקוח</label>
                      <input
                        type="text"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        className='form-control'
                        placeholder="הכנס שם לקוח"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>פרוייקט</label>
                      <input
                        type="text"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        className='form-control'
                        placeholder="הכנס שם פרוייקט"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>חברת</label>
                      <input
                        type="text"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        className='form-control'
                        placeholder="הכנס שם חברה"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>עיר</label>
                      <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className='form-control'
                        placeholder="הכנס עיר"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>טלפון</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className='form-control'
                        placeholder="הכנס טלפון"
                      />
                    </div>
                    <div className="col-md-6">
                      <label>מפיק מסמך</label>
                      <input
                        type="text"
                        value={documentProducer}
                        onChange={(e) => setDocumentProducer(e.target.value)}
                        className='form-control'
                        placeholder="הכנס מפיק מסמך"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <label>נשלח למייל</label>
                      <input
                        type="text"
                        value={emailSent}
                        onChange={(e) => setEmailSent(e.target.value)}
                        className='form-control'
                        placeholder="הכנס מייל"
                      />
                    </div>
                  </div>

                  {/* Billing Table */}
                  <table className='table mt-5'>
                    <thead>
                      <tr>
                        <th>חודש</th>
                        <th>מוצר/תיאור פרט</th>
                        <th>מקום התקנה</th>
                        <th>יחידת מידה</th>
                        <th>כמות</th>
                        <th>מחיר ליחידה</th>
                        <th>סה"כ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billingData.map(item => (
                        <tr key={item.id}>
                          <td>
                            <input
                              type="text"
                              value={item.month}
                              onChange={(e) => handleInputChange(item.id, 'month', e.target.value)}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.description}
                              onChange={(e) => handleInputChange(item.id, 'description', e.target.value)}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.location}
                              onChange={(e) => handleInputChange(item.id, 'location', e.target.value)}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={item.unit}
                              onChange={(e) => handleInputChange(item.id, 'unit', e.target.value)}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleInputChange(item.id, 'quantity', parseFloat(e.target.value))}
                              className='form-control'
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              value={item.unitPrice}
                              onChange={(e) => handleInputChange(item.id, 'unitPrice', parseFloat(e.target.value))}
                              className='form-control'
                            />
                          </td>
                          <td>{item.total.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className='py-4'>
                    <div>סה"כ לפני מע"מ: ₪{subtotal.toFixed(2)}</div>
                    <div>מע"מ 17%: ₪{vat.toFixed(2)}</div>
                    <div>סה"כ כולל מע"מ: ₪{totalWithVat.toFixed(2)}</div>
                  </div>

                  <div className='py-4'>
                    <button type="submit" className='btn btn-primary w-100 py-3 border-0 bold hover-lg'>
                      הגשת טופס
                    </button>
                  </div>
                </form>

                {/* Additional section */}
                <table className='table mt-5'>
                  <thead>
                    <tr>
                      <th>סה"כ לחודש כולל מע"מ</th>
                      <th>מאזן</th>
                      <th>מס' חשבונית</th>
                      <th>מס' קבלה</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>₪ 0.00</td>
                      <td>₪ 0.00</td>
                      <td>₪ 0.00</td>
                      <td>₪ 0.00</td>
                    </tr>
                    <tr>
                      <td>סה"כ לפני מע"מ</td>
                      <td colSpan="3">₪ 0.00</td>
                    </tr>
                    <tr>
                      <td>מע"מ 17%</td>
                      <td colSpan="3">₪ 0.00</td>
                    </tr>
                    <tr>
                      <td>סה"כ כולל מע"מ</td>
                      <td colSpan="3">₪ 0.00</td>
                    </tr>
                    <tr>
                      <td>מאזן</td>
                      <td>מס' חשבונית</td>
                      <td>מס' קבלה</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>₪ 0.00</td>
                      <td>₪ 0.00</td>
                      <td>₪ 0.00</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>סה"כ חשבוניות שהופקו ושולמו</td>
                      <td colSpan="3">₪ 0.00</td>
                    </tr>
                    <tr>
                      <td>יתרה להפקת חשבונית ותשלום</td>
                      <td colSpan="3">₪ 0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

                   
